import React, { useLayoutEffect, useMemo, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';

import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';

import { OptionsStep, SelectContentStep, SelectContentStepData } from '@REDUCERS';
import { ContentChapter } from '@INTERFACES/api/content';

import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import { GenerationMode } from '@PAGES/manager/options/Options.page';
import getInitialChaptersIds from '@PAGES/manager/select-content/helpers/getInitialChaptersIds';
import getInitialSectionsIds from '@PAGES/manager/select-content/helpers/getInitialSectionsIds';
import getInitialVariantsIds from '@PAGES/manager/select-content/helpers/getInitialVariantsIds';
import getDataByTags from '@PAGES/manager/select-content/helpers/getDataByTags';
import filterDataByTags from '@PAGES/manager/select-content/helpers/filterDataByTags';
import filterDataBySearch from '@PAGES/manager/select-content/helpers/filterDataBySearch';
import Chapters from '@PAGES/manager/select-content/components/Chapters';
import Sections from '@PAGES/manager/select-content/components/Sections';

import styles from './Content.module.scss';

const cx: CX = classnames.bind(styles);

function Content(props: Props) {
    const {
        chaptersData,
        isOnlyRequiredSections,
        selectedTagsIds,
        searchValue,
    } = props;

    const {
        actions: documentGenerationStepsActions,
        services: documentGenerationStepsServices,
    } = useDocumentGenerationSteps();

    const optionsStep: OptionsStep = documentGenerationStepsServices.getStepData(GenerationSteps.OPTIONS);
    const selectContentStep: SelectContentStep = documentGenerationStepsServices.getStepData(
        GenerationSteps.SELECT_CONTENT,
    );

    const isPartial = optionsStep!.data.id === GenerationMode.PARTIAL;

    const initialChaptersData = useMemo(
        () => getInitialChaptersIds(chaptersData, isPartial),
        [chaptersData, isPartial],
    );

    const initialSectionsData = useMemo(
        () => getInitialSectionsIds(chaptersData, isPartial),
        [chaptersData, isPartial],
    );

    const initialVariantsData = useMemo(
        () => getInitialVariantsIds(chaptersData, isPartial),
        [chaptersData, isPartial],
    );

    const initialChaptersState = selectContentStep.data
        ? selectContentStep.data.chaptersIds
        : initialChaptersData;
    const initialSectionsState = selectContentStep.data
        ? selectContentStep.data.sectionsIds
        : initialSectionsData;
    const initialVariantsState = selectContentStep.data
        ? selectContentStep.data.variantsIds
        : initialVariantsData;

    const [selectedChaptersIds, setSelectedChaptersIds] = useState<Set<number>>(
        new Set(initialChaptersState),
    );
    const [selectedSectionsIds, setSelectedSectionsIds] = useState<Set<number>>(
        new Set(initialSectionsState),
    );
    const [selectedVariantsIds, setSelectedVariantsIds] = useState<Set<number>>(
        new Set(initialVariantsState),
    );

    useLayoutEffect(() => {
        const { chaptersIds, sectionsIds, variantsIds } = getDataByTags({
            chaptersData,
            initialChaptersIds: selectedChaptersIds,
            initialSectionsIds: selectedSectionsIds,
            initialVariantsIds: selectedVariantsIds,
            selectedTagsIds,
            isPartial,
        });

        setSelectedChaptersIds(chaptersIds);
        setSelectedSectionsIds(sectionsIds);
        setSelectedVariantsIds(variantsIds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chaptersData, selectedTagsIds, isPartial]);

    useLayoutEffect(() => {
        if (isOnlyRequiredSections) {
            const chaptersIds = getInitialChaptersIds(chaptersData, isPartial);
            const sectionsIds = getInitialSectionsIds(chaptersData, isPartial);
            const variantsIds = getInitialVariantsIds(chaptersData, isPartial);

            setSelectedChaptersIds(new Set(chaptersIds));
            setSelectedSectionsIds(new Set(sectionsIds));
            setSelectedVariantsIds(new Set(variantsIds));
        }
    }, [chaptersData, isOnlyRequiredSections, isPartial]);

    useLayoutEffect(() => {
        const data: SelectContentStepData = {
            chaptersIds: Array.from(selectedChaptersIds),
            sectionsIds: Array.from(selectedSectionsIds),
            variantsIds: Array.from(selectedVariantsIds),
            tagsIds: Array.from(selectedTagsIds),
        };

        const anyChapterHasBody = chaptersData
            .filter((chapter) => data.chaptersIds.includes(chapter.id))
            .some((chapter) => chapter.hasBody);

        const anySectionHasBody = chaptersData
            .filter((chapter) => data.chaptersIds.includes(chapter.id))
            .some((chapter) => chapter.sections
                .filter((section) => data.sectionsIds.includes(section.id))
                .some((section) => section.hasBody));

        const isComplete = data.variantsIds.length > 0
            || anySectionHasBody
            || anyChapterHasBody;

        documentGenerationStepsActions.apply({
            id: GenerationSteps.SELECT_CONTENT,
            isComplete,
            data,
        });
    }, [
        documentGenerationStepsActions,
        selectedChaptersIds,
        selectedSectionsIds,
        selectedVariantsIds,
        selectedTagsIds,
        chaptersData,
    ]);

    const hasSelectedChapters = selectedChaptersIds.size > 0;

    // FILTER BY TAGS
    const filteredByTagsData = useMemo(
        () => filterDataByTags({
            chaptersData,
            selectedTagsIds,
            isPartial,
        }),
        [chaptersData, selectedTagsIds, isPartial],
    );

    // FILTER BY SEARCH
    const filteredBySearchData = useMemo(
        () => filterDataBySearch({
            chaptersData: filteredByTagsData,
            searchValue,
        }),
        [filteredByTagsData, searchValue],
    );

    return (
        <div className={cx('content')}>
            {filteredBySearchData.length > 0 ? (
                <>
                    <Chapters
                        chaptersData={filteredBySearchData}
                        selectedChaptersIds={selectedChaptersIds}
                        selectedSectionsIds={selectedSectionsIds}
                        selectedVariantsIds={selectedVariantsIds}
                        setSelectedVariantsIds={setSelectedVariantsIds}
                        setSelectedChaptersIds={setSelectedChaptersIds}
                        setSelectedSectionsIds={setSelectedSectionsIds}
                        isPartial={isPartial}
                    />
                    {hasSelectedChapters && (
                        <Sections
                            chaptersData={filteredBySearchData}
                            selectedChaptersIds={selectedChaptersIds}
                            selectedSectionsIds={selectedSectionsIds}
                            selectedVariantsIds={selectedVariantsIds}
                            setSelectedSectionsIds={setSelectedSectionsIds}
                            setSelectedVariantsIds={setSelectedVariantsIds}
                            isPartial={isPartial}
                            isOnlyRequiredSections={isOnlyRequiredSections}
                        />
                    )}
                </>
            ) : (
                <div className={cx('chapters-not-found', 'container')}>
                    Chapters not found
                </div>
            )}
        </div>
    );
}

Content.propTypes = {
    isOnlyRequiredSections: PropTypes.bool.isRequired,
    selectedTagsIds: PropTypes.instanceOf(Set<number>).isRequired,
    searchValue: PropTypes.string.isRequired,
};

type Props = InferProps<typeof Content.propTypes> & {
    chaptersData: ContentChapter[];
};

export default Content;
